import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postApiRequest } from '../../utils/request';


export default function EmptyStateNotificationRequest({ value, valueKey, headline, details, successHeadline, successDetails }) {
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      try {
        await postApiRequest('/notification-request', { ...values, [valueKey]: value });
        setSubmitted(true);
      } catch (err) {
        actions.setErrors({
          email: 'Invalid email, please try again.',
        });
        // console.log(err.response, 'the error from the request');
      }
      actions.setSubmitting(false);
    },
  });

  if (submitted) {
    return (
      <div className="bg-white shadow sm:rounded-lg max-w-2xl mx-auto">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{successHeadline}</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>{successDetails}</p>
          </div>
        </div>
      </div>
    );
  }

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasEmailError = formik.touched.email && formik.errors.email;
  return (
    <div className="bg-white shadow sm:rounded-lg max-w-2xl mx-auto">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{headline}</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{details}</p>
        </div>
        <form className="mt-5 sm:flex sm:items-center" onSubmit={formik.handleSubmit}>
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              {...formik.getFieldProps('email')}
              className={`${hasEmailError ? errorInputClasses : regularInputClasses} shadow-sm block w-full sm:text-sm rounded-md`}
              placeholder="you@example.com"
            />
            {hasEmailError && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {formik.errors.email}
              </p>
            )}
          </div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Notify me
          </button>
        </form>
      </div>
    </div>
  );
}