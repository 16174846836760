import React, { useEffect, useState } from 'react';
import Container from '../components/layout/default/container';
import Loadable from '../components/data/loadable';
import { getApiRequest } from '../utils/request';
import EmptyState from '../components/empty/empty-state-notification-request';
import CardListWithDetails from '../components/products/card-list-with-details';
import SEO from '../components/layout/seo';

function CenterHeading({ name, city, state, zip, phone, address }) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {name}
          </h2>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            {address}<br />
            {city}, {`${state} ${zip}`}
          </p>
          {!!phone && <p className="mt-1 text-base font-semibold text-indigo-600 tracking-wide uppercase">{phone}</p>}
        </div>
      </div>
    </div>
  );
}

export default function Center({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const { center } = pageContext;

  useEffect(() => {
    // Load the products for this center
    const init = async () => {
      setLoading(true);
      const url = `/products?center_id=${center.id}`;
      try {
        const result = await getApiRequest(url);
        setProducts(result.data);
      } catch (err) {
        console.log(err, 'error');
      }
      setLoading(false);
    };

    init();
  }, []);

  const hasProducts = !!(products && products.length);
  return (
    <Container>
      <SEO title={center.name} />
      <CenterHeading {...center} />
      <Loadable text="Fetching Leagues..." loading={loading}>
        {!hasProducts && (
          <div className="max-w-3xl p-4 sm:py-10 mx-auto">
            <EmptyState
              value={center.id}
              valueKey="center_id"
              headline={`Be the first to know when ${center.name} adds more leagues`}
              details={`Enter your email address to get notified as soon as leagues are added at ${center.name}.`}
              successHeadline="You've been signed up!"
              successDetails={`We'll let you know when leagues are added at ${center.name}.`}
            />
          </div>
        )}
        {hasProducts && <CardListWithDetails products={products} />}
      </Loadable>
    </Container>
  );
}