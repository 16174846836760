import React from 'react';
import { Link } from 'gatsby';
import NumberFormat from 'react-number-format';
import { truncateString, formatDay } from '../../utils/helpers';

export default function CardListWithDetails({ products }) {
  if (!products || !products.length) {
    return null;
  }

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-4 sm:py-8 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {products.map((product) => {
            const { id, start_date: startDate, short_description: shortDescription, description, name, min_age: minAge, total_stock: total, purchased, image, team_size: teamSize, league, center, start_time: startTime } = product;
            const remaining = total - purchased;
            const productUrl = `/${league.slug}/${center.slug}/${id}`;
            return (
              <div
                key={id}
                className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden"
              >
                <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96">
                  <img
                    src={image}
                    alt={name}
                    className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                  />
                </div>
                <div className="flex-1 p-4 space-y-2 flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900">
                    <Link to={productUrl}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {name}
                    </Link>
                  </h3>
                  <p className="text-sm text-gray-500">{truncateString(shortDescription || description, 200)}</p>
                  <div className="flex-1 flex flex-col justify-end">
                    <p className="text-sm italic text-gray-500">Minimum Age: {minAge}</p>
                    {!!teamSize && <p className="text-sm italic text-gray-500">Team Size: {teamSize}</p>}
                    <p className="text-sm italic text-gray-500">{remaining} Spots Remaining</p>
                    <p className="text-sm font-semibold text-gray-500 mt-2 mb-4">Starts: {formatDay(startDate, 'M/D/Y')} at {startTime}</p>
                    <Link
                      to={productUrl}
                      className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
